import { ReactNode } from "react";

import Link from "next/link";

import { classes, sizeClasses } from "./utils";

interface SizeClasses {
  small: string;
  medium: string;
  responsive: string;
}

interface Classes {
  regular: string;
  inverted: string;
  wired: string;
}

interface Button {
  children: ReactNode;
  to: string;
  className?: string;
  external?: boolean;
  type?: keyof Classes;
  size?: keyof SizeClasses;
  id?: string;
}
export default function Button({
  children,
  to,
  className,
  external = false,
  type = "regular",
  size = "medium",
  id = "",
}: Button): JSX.Element {
  if (external)
    return (
      <a
        className={`cursor-pointer self-start rounded transition-colors duration-200 ease-in-out ${sizeClasses[size]} ${classes[type]} ${className}`}
        href={to}
        id={id || to}
        target="_blank"
      >
        {children}
      </a>
    );

  return (
    <Link
      className={`self-start rounded transition-colors duration-200 ease-in-out ${sizeClasses[size]} ${classes[type]} ${className}`}
      href={to}
      id={id || to}
    >
      {children}
    </Link>
  );
}
