import Image from "next/image";

interface ImageLink {
  item: { image: { alt: string; src: string; height: number; width: number }; url: string };
}

export default function ImageLink({ item }: ImageLink): JSX.Element {
  const { image } = item;
  return (
    <a href={item.url} target="_blank">
      <span className="sr-only">{image.alt}</span>
      <Image alt={image.alt} height={image.height} quality={100} src={image.src} width={image.width} />
    </a>
  );
}
