import { ReactNode } from "react";

import Head from "next/head";

interface Layout {
  children: ReactNode;
  landing: string;
  customOG?: boolean;
}

export default function Layout({ children, landing, customOG = false }: Layout): JSX.Element {
  return (
    <>
      <Head>
        {!customOG && (
          <>
            <meta
              content="Simplify is a common application for jobs &amp; internships. Autofill job applications anywhere on the web, get notified when new jobs open, &amp; seamlessly track your applications."
              property="og:description"
            />
            <meta content="1200" property="og:image:width" />
            <meta content="630" property="og:image:height" />
            <meta content="Simplify | Find Your Dream Job 10x Faster" property="og:title" />
            <meta content="https://simplify.jobs" property="og:url" />
            <meta
              content="https://res.cloudinary.com/dpeo4xcnc/image/upload/v1687465687/og_simplify2.png"
              property="og:image"
            />
            <meta content="Simplify Jobs" property="og:image:alt" />
          </>
        )}
        <meta content="Simplify Jobs" property="og:site_name" />
        <meta content="website" property="og:type" />
      </Head>
      <main className={`${landing} font-circular`}>
        {/* {banner && <Banner rounded={false} />} */}
        {children}
      </main>
    </>
  );
}
