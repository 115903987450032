import { Fragment, useState } from "react";

import { Menu, Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { HiMenu, HiOutlineX } from "react-icons/hi";

import Button from "components/Landing/Button";
import ExternalLogo from "components/Landing/ExternalLogo";
import Logo from "components/Landing/Logo";
import { navigation } from "utils/constants";
import useAuth from "utils/hooks/useAuth";

import { jobMenu } from "./constants";

interface Nav {
  externalLogo?: {
    src: string;
    url: string;
    alt: string;
    size: string;
  };
  inverted?: boolean;
  blueIcon?: boolean;
}

export default function Nav({ externalLogo, inverted = false, blueIcon = false }: Nav): JSX.Element {
  const { isAuthenticated, loading } = useAuth();
  const [showMore, setShowMore] = useState(false);

  return (
    <Popover className="relative z-50">
      <div className="flex items-center justify-between md:px-6 nav:justify-start nav:space-x-8">
        <div className={externalLogo ? "flex items-center" : ""}>
          <Logo blueIcon={blueIcon} externalLogo={!!externalLogo} inverted={inverted} />
          {externalLogo && (
            <ExternalLogo
              alt={externalLogo.alt}
              size={externalLogo.size}
              src={externalLogo.src}
              url={externalLogo.url}
            />
          )}
        </div>
        <div className={`-my-2 -mr-2 flex items-center ${isAuthenticated ? "navLoggedIn:hidden" : "nav:hidden"}`}>
          {!isAuthenticated && (
            <Button
              className="mr-1 rounded-full border-2 border-primary-400 text-center"
              size={externalLogo ? "responsive" : "medium"}
              to="/auth/register"
              type="regular"
            >
              Sign Up
            </Button>
          )}
          <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500">
            <span className="sr-only">Open menu</span>
            <HiMenu aria-hidden="true" className="size-6" />
          </Popover.Button>
        </div>
        <div className="hidden nav:flex nav:flex-1 nav:items-center nav:justify-between">
          <Popover.Group as="nav" className="mr-4 flex items-center space-x-8 xl:space-x-10">
            {navigation.map((item) => (
              <Link
                key={item.name}
                className={`text-base font-medium ${
                  inverted ? "text-gray-200 hover:text-white" : "text-gray-500 hover:text-gray-900"
                } ${item.hideOnMobile ? "hidden navconflict:block" : ""}`}
                href={item.href}
              >
                {item.name}
              </Link>
            ))}
            <Menu as="div" className="ml-3 hidden md:block" data-testid="menu">
              {({ open }): JSX.Element => (
                <>
                  <div>
                    <Menu.Button
                      className="flex cursor-default items-center text-sm focus:outline-none md:cursor-pointer"
                      data-testid="menu-button"
                    >
                      <span className="sr-only">Open user menu</span>
                      <div
                        className={`flex items-center text-base font-medium ${
                          inverted ? "text-gray-200 hover:text-white" : "text-gray-500 hover:text-gray-900"
                        }`}
                        data-testid="desktop-name"
                      >
                        <p className="flex flex-nowrap">More</p>
                        <svg
                          className={`-mr-1 ml-1 size-5 ${open ? "rotate-180" : ""}`}
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    show={open}
                  >
                    <Menu.Items
                      static
                      className="absolute z-[1] mt-4 hidden w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none md:block"
                      data-testid="profile-menu"
                    >
                      {jobMenu.map((item) => (
                        <Menu.Item key={item.key}>
                          <Link
                            className={`flex items-center px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 ${item.hideOnMobile ? "block navconflict:hidden" : ""}`}
                            href={item.href}
                            role="menuitem"
                          >
                            {item.name}
                          </Link>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </Popover.Group>
          {isAuthenticated && !loading ? (
            <Button
              className="rounded-full border-2"
              size={externalLogo ? "responsive" : "medium"}
              to="/dashboard"
              type="wired"
            >
              Dashboard
            </Button>
          ) : (
            <div className="flex items-center gap-4">
              <Button
                className="rounded-full border-2 text-center"
                size={externalLogo ? "responsive" : "medium"}
                to="/auth/login"
                type="wired"
              >
                Log In
              </Button>
              <Button
                className="rounded-full border-2 border-primary-400 text-center hover:border-primary-500"
                size={externalLogo ? "responsive" : "medium"}
                to="/auth/register"
                type="regular"
              >
                Sign Up
              </Button>
            </div>
          )}
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 z-50 origin-top-right transition nav:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black/5">
            <div className="px-5 pb-6 pt-5">
              <div className="flex items-center justify-between">
                <div className={externalLogo ? "flex items-center" : ""}>
                  <Logo externalLogo={!!externalLogo} />
                  {externalLogo && (
                    <ExternalLogo
                      alt={externalLogo.alt}
                      size={externalLogo.size}
                      src={externalLogo.src}
                      url={externalLogo.url}
                    />
                  )}
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <HiOutlineX aria-hidden="true" className="size-6" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                      href={item.href}
                    >
                      <div className="ml-4 text-base font-medium text-secondary-400">{item.name}</div>
                    </Link>
                  ))}
                  <Link
                    key="moonshots"
                    className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                    href="/moonshot/web3"
                  >
                    <div className="ml-4 text-base font-medium text-secondary-400">🚀 Moonshots</div>
                  </Link>
                  <button
                    className="ml-4 flex w-full items-center gap-2 text-left text-base font-bold text-gray-500"
                    type="button"
                    onClick={() => setShowMore(!showMore)}
                  >
                    <p>More</p>
                    <svg
                      className={`-mr-1 ml-1 size-5 ${showMore ? "rotate-180" : ""}`}
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                  {showMore && (
                    <div className="ml-4 grid gap-6">
                      {jobMenu.map((item) => (
                        <Link
                          key={item.key}
                          className={`-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50 ${item.hideOnMobile ? "hidden" : ""}`}
                          href={item.href}
                        >
                          <div className="ml-4 text-base font-medium text-secondary-400">{item.name}</div>
                        </Link>
                      ))}
                    </div>
                  )}
                </nav>
              </div>
            </div>
            <div className="px-5 py-6">
              <div>
                {isAuthenticated && !loading ? (
                  <Button
                    className="flex w-full justify-center"
                    size={externalLogo ? "responsive" : "medium"}
                    to="/dashboard"
                    type="regular"
                  >
                    Dashboard
                  </Button>
                ) : (
                  <Button
                    className="flex w-full justify-center"
                    size={externalLogo ? "responsive" : "medium"}
                    to="/auth/login"
                    type="regular"
                  >
                    Log In
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
