import Image from "next/image";
import Link from "next/link";

interface ExternalLogo {
  src: string;
  url: string;
  alt: string;
  size: string;
}

export default function ExternalLogo({ src, url, alt, size }: ExternalLogo): JSX.Element {
  return (
    <>
      <p className="px-2 text-xs font-bold sm:text-base">X</p>
      <Link aria-label="Logo" className={`relative ${size} flex shrink-0`} href={url} rel="noopener" target="_blank">
        <Image fill priority alt={alt} className={alt === "Rewriting_the_Code" ? "rounded" : ""} src={src} />
      </Link>
    </>
  );
}
