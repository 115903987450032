// eslint-disable-next-line import/prefer-default-export
export const jobMenu = [
  { name: "Internships", href: "/internships", key: "internships" },
  { name: "Entry Level & New Grad", href: "/entry-level", key: "entry" },
  { name: "Experienced Job Seekers", href: "/experienced-job-seekers", key: "experienced-job-seekers" },
  { name: "Remote Work", href: "/remote", key: "remote" },
  { name: "Browse Jobs", href: "/jobs", key: "jobs" },
  { name: "Resume Review", href: "/services/resume", key: "resume" },
  { name: "Blog", href: "/blog", key: "blog", hideOnMobile: true },
  { name: "About", href: "/about", key: "about" },
];
