export const items = [
  {
    label: "Support & FAQ",
    url: "https://help.simplify.jobs",
  },
  {
    label: "Terms",
    url: "/terms",
  },
  {
    label: "Privacy",
    url: "/privacy",
  },
  { label: "Service Status", url: "https://simplify.instatus.com/" },
];

export const itemsSecondRow = [
  {
    label: "Careers",
    url: "/c/Simplify",
  },
  { label: "Moonshots", url: "/moonshot/web3" },
  { label: "Latest Jobs", url: "/latest-jobs" },
  { label: "Latest Companies", url: "/companies-list" },
];

export const social = [
  {
    url: "https://www.tiktok.com/@joinsimplify",
    image: {
      src: "/images/landing/social/tiktok.png",
      alt: "TikTok",
      width: 28,
      height: 28,
    },
  },
  {
    url: "https://www.linkedin.com/company/simplifyjobs/",
    image: {
      src: "/images/landing/social/linkedin.png",
      alt: "LinkedIn",
      width: 28,
      height: 28,
    },
  },
  {
    url: "https://twitter.com/joinsimplify/",
    image: { src: "/images/landing/social/twitter.png", alt: "Twitter", width: 28, height: 28 },
  },
  {
    url: "https://www.reddit.com/r/simplify/",
    image: {
      src: "/images/landing/social/reddit.png",
      alt: "Reddit",
      width: 29,
      height: 29,
    },
  },
];
