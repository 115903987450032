// eslint-disable-next-line import/prefer-default-export
export const lists = [
  {
    title: "Top Summer 2025 Internships",
    link: "/l/Top-Summer-Internships",
    type: "internship",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/f31b001b-ae9b-4cf1-a6f2-bdc5b9f6b003/icon/image.png",
  },
  {
    title: "Senior Software Roles @ Unicorn Startups",
    link: "/l/Senior-Software-Roles-at-Unicorn-Startups",
    type: "fulltime",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/9ffcb55c-6237-4dc0-9af3-139eb7635589/icon/image.png",
  },
  {
    title: "New Grad Roles in NYC",
    link: "/l/New-Grad-Roles-in-New-York-City",
    type: "fulltime",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/ad193c19-c742-484f-992a-b41a80e49dfb/icon/image.png",
  },
  {
    title: "Top Entry-Level Remote Jobs",
    link: "/l/Best-Remote-Roles-for-New-Grads",
    type: "fulltime",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/f7935e37-c3c7-4aac-ba75-8ad735fdcf25/icon/image.png",
  },
  {
    title: "Internships in the SF Bay Area",
    link: "/l/Internships-in-SF-Bay-Area",
    type: "internship",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/ec34e3f7-ccc1-48cf-b1f5-897a1c93f37f/icon/image.png",
  },
  {
    title: "Top Marketing Internships",
    link: "/l/Marketing-Internships",
    type: "internship",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/ce28b1bd-fe7e-4212-8e58-061c7e1cab29/icon/image.png",
  },
  {
    title: "Entry Level UI/UX Design Jobs",
    link: "/l/Entry-Level-UI-UX-Jobs",
    type: "fulltime",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/90f7fe6a-741e-4a97-b296-31329553eacd/icon/image.png",
  },
  {
    title: "Entry Level IT & Cybersecurity Jobs",
    link: "/l/Entry-Level-Cybersecurity-IT-Jobs",
    type: "fulltime",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/58d55006-05fc-469d-a650-c5958711dcbb/icon/image.png",
  },
  {
    title: "Internships @ Unicorns",
    link: "/l/Unicorn-Internships",
    type: "internship",
    icon: "https://storage.googleapis.com/simplify-imgs/curated_lists/ff38c2fc-9433-40a8-9ad1-48cb00aa2e0f/icon/image.png",
  },
];
