export const sizeClasses = {
  small: "text-sm px-3 py-3",
  medium: "text-base px-4 py-2",
  responsive: "text-sm sm:text-base px-3 py-3 sm:px-5 sm:py-3",
};

export const classes = {
  regular: `text-white bg-primary-400 hover:bg-primary-500`,
  inverted: `text-primary-400 bg-white hover:bg-primary-100`,
  wired: `text-primary-400 border-primary-400 border-2 bg-none hover:text-white hover:bg-primary-400`,
};
